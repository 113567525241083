export function vec3ToVec2(
  vec3: [number, number, number],
  matrix4: number[],
  w: number
) {
  let x: number = vec3[0]
  let y: number = vec3[1]
  let z: number = vec3[2]
  if (matrix4) {
    const e = matrix4
    const X = e[0] * x + e[1] * y + e[2] * z + e[3] * w
    const Y = e[4] * x + e[5] * y + e[6] * z + e[7] * w
    const Z = e[8] * x + e[9] * y + e[10] * z + e[11] * w
    const W = e[12] * x + e[13] * y + e[14] * z + e[15] * w
    x = X
    y = Y
    z = Z
    w = W
  }
  return { x, y }
}
