import * as PIXI from 'pixi.js';
import { Viewport } from 'pixi-viewport'
import { EventEmitter } from './utils/event-emitter';
import { spotType } from './interface/spot';
import { Spot } from './entity/spot';
import { Path } from './entity/path';
import { Label } from './entity/label';
import _ from 'lodash';
import { pathType } from './interface/path';
import { Iposition, stageState, displayIndex } from './interface/base';

const Loader = PIXI.Loader.shared;
const TextureCache = PIXI.utils.TextureCache;
const startSpotSignUrl = 'https://expo-cdn-daily.oss-cn-hangzhou.aliyuncs.com/image/start-point.png';
const curSpotSignUrl = 'https://img.alicdn.com/imgextra/i3/O1CN01HJbDFY1Ccl3zNcfxP_!!6000000000102-2-tps-40-44.png';


export default class RoamingDraw extends EventEmitter<any> {
  PIXI: any = null;
  app: any = null;
  viewport: any = null;
  originList: Array<any> = [];
  spotsList: Array<Spot> = [];
  spotsMap: Map<string, Spot> = new Map();
  pathList: Array<Path> = [];
  stageState: stageState = stageState.view;
  locationSign: any = null;
  wrapContainer: any = null;

  constructor(
    public container: HTMLElement,
    public option?: any,
  ) {
    super();
    this._init();
  }

  /**
   * 初始化加载画布
   */
  private async _init() {
    this._initApp();
    this._initSeting();
    this.loadResource();
  }

  /**
   * 初始化pixi引擎
   */
  private _initApp() {
    PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL; // webgl context 设置成1.0
    this.PIXI = PIXI;
    const defaultOption = {
      antialias: true,
      transparent: true
    }
    const pixiOption = _.assign({}, defaultOption, this.option)
    this.app = new PIXI.Application(pixiOption);
    this.container.appendChild(this.app.view);
    this.wrapContainer = new PIXI.Container()
  }

  /**
   * 初始化设置
   */
  private _initSeting() {
    this.wrapContainer.sortableChildren = true; // 绘制的精灵显示层级按照zIndex排序
  }
  /**
   * 加载图片资源
   */
  private loadResource() {
    const bgImg = this.option.bgImg;
    if (!TextureCache[curSpotSignUrl]) {
      Loader.add(curSpotSignUrl);
    }
    if (bgImg && !TextureCache[bgImg]) {
      Loader.add(bgImg);
    }
  }

  /**
   * 初始化viewport
   */
  private async _initViewport() {
    const viewport = new Viewport({
      worldWidth: this.option.width,
      worldHeight: this.option.height,
      interaction: this.app.renderer.plugins.interaction
    })

    this.viewport = viewport;
    this.app.stage.addChild(viewport);
    this.viewport.addChild(this.wrapContainer);
    viewport.drag().pinch().wheel().decelerate();
  }

  setScale(number: number) {
    this.viewport.scale.set(Number((this.viewport.scale.x + number).toFixed(1)), Number((this.viewport.scale.y + number).toFixed(1)))
    this.spotsList.forEach(spot => {
      spot.circle.scale.set(Number((spot.circle.scale.x - number).toFixed(1)), Number((spot.circle.scale.y - number).toFixed(1)))
    })
    this.pathList.forEach(path => {
      const width = path.line.line.width
      const color = path.line.line.color
      path.line.clear()
      path.line.lineStyle(Number((width - number).toFixed(1)), color, 1)
      path.drawLine()
    })
  }

  /**
   * 初始化背景图
   * @returns
   */



  private async _initBackground() {
    return new Promise((resolve) => {
      const bgImg = this.option.bgImg;
      Loader.load(() => {
        let bgSprite = new this.PIXI.Sprite(TextureCache[bgImg]);
        bgSprite.width = this.option.width;
        bgSprite.height = this.option.height;
        this._addElementToStage(bgSprite);
        resolve(bgSprite);
      });
    })
  }



  /**
   * 初始化舞台
   * @param list
   */
  public async initStage(list: Array<any>) {
    await this._destroyViewport(); // 先清除上一次绘制的舞台
    await this._initViewport();
    await this._initBackground();
    this.originList = list
    _.forEach(list, (item: any) => {
      this.initSpot(item);
    })
    this.initPath();
    this._initLocationSign();
    this.wrapContainer.updateTransform();
  }

  /**
   * 清空viewport
   * @returns
   */
  private async _destroyViewport() {
    if (this.viewport) {
      this.originList = []
      this.spotsList = [];
      this.pathList = [];
      this.locationSign = null;
      this.wrapContainer.removeChildren();
      this.viewport.destroy();
    }
  }


  /**
   * 初始化spot点
   * @param config
   */
  public initSpot(config: any) {
    const { name, type, position, pathIndex, isFirstIndex, isLastIndex, isSelected } = config;
    const spotExisted = this.spotsList.find(spot => spot.name === name)
    if(spotExisted) {
      if(spotExisted.pathIndex === void 0) {
        spotExisted.pathIndex = pathIndex
      } else {
        spotExisted.pathIndex = Math.max(spotExisted.pathIndex, pathIndex)
      }
      spotExisted.isFirstIndex = spotExisted.isFirstIndex || isFirstIndex
      spotExisted.isLastIndex = spotExisted.isLastIndex || isLastIndex
      return
    }
    const spot = new Spot({
      name,
      type,
      position,
      pathIndex,
      isFirstIndex,
      isLastIndex,
      isSelected
    })
    if (isSelected) {
      spot.setSelected(true);
    } else {
      spot.setSelected(false);
    }
    spot.circle.zIndex = pathIndex === void 0 ? displayIndex.uselessSpotIndex : displayIndex.spotIndex
    this._addElementToStage(spot.circle);
    const label = new Label({
      name,
      position: {
        x: 0 - 6.5,
        y: 0 - 8
      }
    })
    label.text.zIndex = displayIndex.spotLableIndex;
    spot.circle.addChild(label.text)
    this.spotsList.push(spot);
    this.spotsMap.set(spot.name, spot)
    spot.on('spotClick', this.handleSpotClick.bind(this))
  }

  public deleteSpotInPath(index: number) {
    const linePath = this.pathList[index]
    const preLinePath = this.pathList[index - 1]
    if(!linePath) {
      return
    }
    this.pathList.splice(index, 1)
    if (preLinePath) {
      this.pathList.splice(index - 1, 1)
    }
    this._removeElementFromStage(linePath!.line);
    this._removeElementFromStage(preLinePath!.line);
    const preStartSpot = preLinePath.startSpot
    const preEndSpot = preLinePath.endSpot
    const endSpot = linePath.endSpot
    preEndSpot.setCount(preEndSpot.count - 1)
    endSpot.setCount(endSpot.count - 1)
    if(preStartSpot.name !== endSpot.name) {
      const path = new Path({
        name: `${preStartSpot.name}-${endSpot.name}`,
        type: 'inRoam',
        startSpot: preStartSpot,
        endSpot: endSpot,
        startPosition: preStartSpot.position,
        endPosition: endSpot.position,
      })
      endSpot.setCount()
      path.line.zIndex = displayIndex.pathIndex;
      this._addElementToStage(path.line);
      this.pathList.splice(index-1, 0, path)
    }
  }

  /**
   * 初始化路径
   */
  public initPath() {
    const lineSpotList = _.filter(this.originList, (item: Spot) => {
      return !!item.pathIndex
    })
    const sortList = _.sortBy(lineSpotList, (item: Spot) => {
      return item.pathIndex
    })
    if (!sortList.length) {
      return
    }
    for (let i = 0; i < sortList.length - 1; i++) {
      let startPoint = this.spotsMap.get(sortList[i].name)!
      let endPoint = this.spotsMap.get(sortList[i + 1].name)!
      const path = new Path({
        name: `${startPoint.name}-${endPoint.name}`,
        type: startPoint.type === 'inVoice' && endPoint.type === 'inVoice' ? 'inVoice' : 'inRoam',
        startSpot: startPoint,
        endSpot: endPoint,
        startPosition: startPoint.position,
        endPosition: endPoint.position,
      })
      endPoint.setCount()
      path.line.zIndex = displayIndex.pathIndex;
      this._addElementToStage(path.line);
      this.pathList.push(path);
    }
  }

  /**
   * 初始化起始点标签
   * @param positon
   */
  public initStartPointSign(positon: Iposition) {
    let sign = new this.PIXI.Sprite(TextureCache[startSpotSignUrl]);
    sign.x = positon.x - 20;
    sign.y = positon.y + 20;
    sign.width = 40;
    sign.height = 40;
    this._addElementToStage(sign)
  }

  private _initLocationSign() {
    this.locationSign = new this.PIXI.Sprite(TextureCache[curSpotSignUrl]);
    this.locationSign.width = 40;
    this.locationSign.width = 40;
    this.locationSign.anchor.x = 0.5;
    this.locationSign.anchor.y = 0.85;
    this.locationSign.zIndex = displayIndex.locationSignIndex;
    const selectedSpot = _.filter(this.spotsList, (item: any) => {
      return item.isSelected === true;
    })[0];
    if (selectedSpot) {
      this.locationSign.x = selectedSpot.position.x;
      this.locationSign.y = selectedSpot.position.y;
    }
    this._addElementToStage(this.locationSign)
  }

  /**
   * 标识当前位置
   */
  public markLocation(positon: Iposition, angle: number) {
    this.locationSign.x = positon.x;
    this.locationSign.y = positon.y;
    this.locationSign.rotation = angle;
  }

  /**
   * spot点击事件处理
   * @param target
   * @returns
   */
  private handleSpotClick(target: any) {
    if (this.stageState === stageState.view) {
      // 视图态逻辑处理
      this._handleSpotSelect(target.name);
      this.emit('spotClick', target);
      return
    }
    // 编辑态逻辑处理
    switch (target.type) {
      case 'default': {
        this._handleSpotSelect(target.name);
        target.changeSpotType(spotType.inPath);
        this._drawPathToSpot(target);
        break;
      }
      case 'inPath': {
        if(Spot.selectName === target.name) {
          return
        }
        this._handleSpotSelect(target.name);
        target.changeSpotType(spotType.inPath);
        this._drawPathToSpot(target);
        break;
      }
      case 'inVoice': {
        if (Spot.selectName === target.name) {
          return
        }
        this._handleSpotSelect(target.name);
        target.changeSpotType(spotType.inVoice);
        this._drawPathToSpot(target);
        break;
      }
    }

  }

  /**
   * spot点之间连线
   * @param spot
   */
  private _drawPathToSpot(spot: any) {
    const lastIndexSpot = _.filter(this.spotsList, (item: any) => {
      return item.isLastIndex === true;
    })[0];
    if (lastIndexSpot) {
      const { name, position, pathIndex } = lastIndexSpot;
      const path = new Path({
        name: `${name}-${spot.name}`,
        type: 'inRoam',
        startSpot: lastIndexSpot,
        endSpot: spot,
        startPosition: position,
        endPosition: spot.position,
      })
      spot.setCount()
      this._addElementToStage(path.line);
      this.pathList.push(path);
      lastIndexSpot.setLastIndex(false);
      spot.setLastIndex(true);
      spot.setPathIndex(pathIndex as number + 1);
      this.emit('addSpotToRoam', spot);
    } else {
      throw new Error('not find last index spot');
    }
  }

  public revokeDrawPath() {
    if (this.stageState === stageState.view) {
      // view态不允许撤销
      return
    }
    if(!this.pathList.length)  {
      return
    }
    const linePath = this.pathList.pop()
    const startSpot = linePath?.startSpot
    const endSpot = linePath?.endSpot!
    let endPathIndex
    this.pathList.forEach((path, index) => {
      if (path.name.includes(endSpot!.name)) {
        endPathIndex = index
      }
    })
    if (endPathIndex !== void 0) {
      endSpot.changeSpotType(spotType.inPath);
      endSpot.setLastIndex(false);
      endSpot.setPathIndex(endPathIndex)
    } else {
      endSpot.changeSpotType(spotType.default);
      endSpot.setLastIndex(false);
      endSpot.setPathIndex(undefined)
    }
    if(endSpot.count > 1) {
      endSpot.setCount(endSpot.count - 1)
    }
    this._removeElementFromStage(linePath!.line);
    startSpot!.setLastIndex(true);
    Spot.selectName = startSpot!.name
    this.emit('removeSpotFromRoam', startSpot)
  }


  /**
  * 处理spot点的选中情况
  * @param excludeSpot
  */
  private _handleSpotSelect(excludeSpot: string) {
    _.forEach(this.spotsList, (item: any) => {
      if (excludeSpot === item.name) {
        item.setSelected(true);
      } else {
        item.setSelected(false);
      }
    })
  }

  /**
   * 组件进入编辑态
  */
  public enterEdit() {
    this._setStageState(stageState.edit);
  }

  /**
   * 组件退出编辑态
   */
  public quitEdit() {
    this._setStageState(stageState.view);
  }

  /**
   * 设置组件交互状态
   * @param state 'view' | 'edit'
   */
  private _setStageState(state: stageState) {
    this.stageState = state
  }

  /**
   * 改变spot组状态
   * @param list
   * @param type
   */
  public setSpotGroup(list: Array<string>, type: spotType) {
    const nameList = [] as any;
    for (let i = 0; i < list.length; i++) {
      this.changeSpotType(list[i], type);
      if (type !== spotType.default && i < list.length - 1) {
        nameList.push(list[i] + '-' + list[i + 1])
      }
    }
    _.forEach(nameList, (name: string) => {
      this.changePathType(name, type as any);
    })
  }

  /**
   * 改变单个spot点类型
   * @param name
   * @param type
   * @returns
   */
  public changeSpotType(name: string, type: spotType) {
    const spot = this._getSpotByName(name);
    if (!spot) {
      return;
    }
    spot.changeSpotType(type);
  }

  /**
   * 改变单条路线类型
   * @param name
   * @param type
   * @returns
   */
  public changePathType(name: string, type: pathType) {
    const path = this._getPathByName(name);
    if (!path) {
      return;
    }
    path.changePathType(type);
  }

  /**
   * 根据name获取spot点实例
   * @param name
   * @returns
   */
  private _getSpotByName(name: string) {
    const spot = _.filter(this.spotsList, (item: any) => {
      return item.name === name;
    })
    return spot.length ? spot[0] : null;
  }

  /**
   * 根据name获取path实例
   * @param name
   * @returns
   */
  private _getPathByName(name: string) {
    const path = _.filter(this.pathList, (item: any) => {
      return item.name === name;
    })
    return path.length ? path[0] : null;
  }



  /**
   * 添加元素到舞台上
   * @param element
   * @returns
   */
  private _addElementToStage(element: any) {
    return this.wrapContainer.addChild(element);
  }

  /**
   * 从舞台上移除元素
   * @param element
   * @returns
   */
  private _removeElementFromStage(element: any) {
    return this.wrapContainer.removeChild(element);
  }

  install(app: any, options: any) {
    this.app = app;
    const sceneLoadEnd = app.lifeHooks.sceneLoadEnd;
    const update = app.lifeHooks.update

    app.lifeHooks.sceneLoadEnd = async () => {
      sceneLoadEnd(); // 必须调用
    }
    app.lifeHooks.update = (deltaTime: any, inputContext: any) => {
      update(deltaTime, inputContext); // 必须调用
    }
  }
}


