import { defaultLabelStyle } from '../config/label';
import { Iposition } from '../interface/base';
import * as PIXI from 'pixi.js';
import _ from 'lodash';

export class Label {

  text: any;
  name: string;
  position: Iposition;
  style: any;
  constructor (config: any) {
    const { name, position, style} = config;
    this.name = name;
    this.position = position;
    this.style = _.assign({}, defaultLabelStyle, style)
    this._initLabel()
  }

  /**
   * 初始化label
   */
  private _initLabel() {
    this.text = new PIXI.Text(this.name, this.style);
    this.text.position.set(this.position.x, this.position.y);
  }
}

