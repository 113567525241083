import { spotType, IspotStyleConfig } from '../interface/spot';
import { displayIndex, Iposition } from '../interface/base';
import { spotStyleConfig, doubleSpotTexture } from '../config/spot';
import { EventEmitter } from "../utils/event-emitter";
import * as PIXI from 'pixi.js';

export class Spot extends EventEmitter<any>{
  name: string;
  type: spotType;
  position: Iposition;
  isSelected: boolean = false;
  circle: any;
  sprite: any;
  spotStyle: IspotStyleConfig = spotStyleConfig[spotType.default];
  pathIndex: number | undefined;
  isFirstIndex: boolean;
  isLastIndex: boolean;
  count: number = 0

  static selectName: string | null = null

  constructor (config: any) {
    super();
    const { name, type, position, pathIndex, isFirstIndex, isLastIndex} = config;
    this.name  = name;
    this.type = type;
    this.position = position;
    this.pathIndex = pathIndex;
    this.isFirstIndex = isFirstIndex;
    this.isLastIndex = isLastIndex;
    this._initSpot();
    this._drawSpot();
  }

  /**
   * 初始化spot
   */
  private _initSpot() {
    this.circle = new PIXI.Graphics();
    this.circle.interactive = true; // 响应交互
    this.circle.buttonMode = true; // 鼠标变手型
    this.spotStyle = spotStyleConfig[this.type];
    this.circle.on('click', () => { this.emit('spotClick', this) })
  }

  /**
   * 绘制spot点
   * @param position
   */
  public _drawSpot() {
    this.circle.beginFill(this.spotStyle.fillColor);
    this.circle.lineStyle(this.spotStyle.outlineWidth, this.isSelected ? this.spotStyle.selectedOutlineColor : this.spotStyle.outlineColor, 1);
    this.circle.drawCircle(0, 0, this.spotStyle.circleRadius);
    this.circle.endFill();
    this.circle.x = this.position.x;
    this.circle.y = this.position.y;
  }

  /**
   * 改变spot类型
   * @param type spot类型
   */
  public changeSpotType(type: spotType) {
    this._setProperty('type', type);
    this.circle.clear();
    this.circle.beginFill(spotStyleConfig[type].fillColor);
    this.circle.lineStyle(spotStyleConfig[type].outlineWidth, spotStyleConfig[type].outlineColor, 1);
    this.circle.drawCircle(0, 0, spotStyleConfig[type].circleRadius);
    this.circle.endFill();
  }

  /**
   * 设置spot点的可交互状态
   * @param isActive
   */
  public setSpotActive(isActive: boolean) {
    this.circle.interactive = isActive; // 响应交互
    this.circle.buttonMode = isActive; // 鼠标变手型
  }

  /**
   * 设置spot点的选中状态
   * @param isSelected
   */
  public setSelected(isSelected: boolean) {
    this._setProperty('isSelected', isSelected)
    if(isSelected) {
      Spot.selectName = this.name
    }
    this._drawSpot()
  }

  /**
   * 设置spot点的序号是否是最后
   * @param isLastIndex
   */
  public setLastIndex(isLastIndex: boolean) {
    this._setProperty('isLastIndex', isLastIndex)
  }

  /**
   * 设置spot点的序号
   * @param index
   */
  public setPathIndex(index: number | undefined) {
    this._setProperty('pathIndex', index)
    this.circle.zIndex = index === void 0 ? displayIndex.uselessSpotIndex : displayIndex.spotIndex
  }

  public setCount(number?: number) {
    if(number !== undefined) {
      this.count = number
    } else {
      this.count += 1
    }
    if(this.count === 2) {
      const base = new PIXI.BaseTexture(doubleSpotTexture)
      const texture = new PIXI.Texture(base)
      const sprite = new PIXI.Sprite(texture)
      this.sprite = sprite
      sprite.zIndex = 5
      sprite.width = 22
      sprite.height = 22
      sprite.x = -11
      sprite.y = -11
      sprite.alpha = 0.9
      this.circle.addChild(sprite)
    }
    if(this.count === 1 && this.sprite) {
      this.circle.removeChild(this.sprite)
    }
  }

  /**
   * 设置属性和对应值
   * @param property
   * @param value
   */
  private _setProperty(property: string, value: any) {
    Object.assign(this, {
      [property]: value
    })
  }
}
