import { vec3, vec4 } from "gl-matrix";
import { getImage, WanHuaTongUtility } from "../../src/GameLogic/WanHuaTongUtility";
import { canvasToText } from "../../src/lib/utils/canvasToText";
import { titleBackUrl, highLightBackUrl } from './icon'

const MaxLineLength = 12;
const MinLineLength = 3;
const MaxTitleScale = 10;
const MinTitleScale = 1;
const highLightLineColor = [0.1, 0.93, 0.93, 1.0];
const scaleLength = 2

export default class SpotTagPlugin {

  public name = 'SpotTagPlugin';
  public app: any;
  public tide: any;
  public options = {
    lineLength: 4.5,
    titleScale: 1,
  }
  public spotLineList: Array<any> | undefined = [];
  public spotTitleBackList: Array<any> | undefined = [];
  public spotTitleList: Array<any> | undefined = [];
  public spotTagList: Array<any> | undefined = [];
  private spotTagShowMap = {} as any;
  private isHighLight = false;
  private titleBackTexture!: any;
  private highLightBackTexture!: any;
  private scaleRatio!: number;
  private multiFloor = false;
  private _time = 0;
  private crtFloorStatus = 'total'

  install(app: any, options?: any) {
    this.app = app;
    this.tide = app.tide;
    this.options = Object.assign({}, this.options, options);
    this._initOptions();
    this.scaleRatio = (this.options.titleScale - 1) * 0.35;
    // sdk提供的生命周期函数
    const resourceLoadEnd = app.lifeHooks.resourceLoadEnd;
    const sceneLoadEnd = app.lifeHooks.sceneLoadEnd;
    const update = app.lifeHooks.update;

    // 获取sdk核心api
    const transitionAPI = app.getInstance("transitionControlAPI") as any;
    const configAPI = app.getInstance("configAPI") as any;

    app.lifeHooks.resourceLoadEnd = async () => {
      this.isHighLight = false;
      const modelConfig = configAPI.getConfig();
      const scenes = modelConfig.scenes;
      const entryMode = modelConfig.default.firstScene;
      const multiFloor = modelConfig?.multiFloor
      if (multiFloor && multiFloor.floorNumber > 1) {
        this.multiFloor = true;
      }
      const titleBackTexture = await this.creatTextureByImage(titleBackUrl)
      const highLightBackTexture = await this.creatTextureByImage(highLightBackUrl)
      this.titleBackTexture = titleBackTexture
      this.highLightBackTexture = highLightBackTexture
      for (const spotId in scenes) {
        const spotInfo = scenes[spotId];
        const startPosition = WanHuaTongUtility.matchCoordSys(spotInfo.spotPose.groundCoord);
        const endPosition = vec3.create();
        const spotNormal = vec3.fromValues(0, 1, 0);
        vec3.add(endPosition, startPosition, vec3.scale(vec3.create(), spotNormal, this.options.lineLength));
        let textString = '';
        // spotInfo.label = {name:"客厅01", visible: true}; // 本地自测数据
        if (spotInfo.label?.name) {
          textString = spotInfo.label.name.length > 4 ? `${spotInfo.label.name.substring(0, 4)}...` : spotInfo.label.name;
        } else {
          textString = '未命名';
        }
        const spotTag = await this._createSpotTag({
          spotId,
          startPosition,
          endPosition,
          normal: spotNormal,
          name: textString,
          visible: spotInfo.label?.visible
        })
        const scene = this.tide.SceneManager.GetInstance().currentScene;
        scene.addEntity(spotTag);
        this.spotTagList!.push(spotTag);
        const childModels = spotTag?.getChildrenEntitiesRecursively();
        childModels?.forEach((item: any) => {
          if (item.name.indexOf('_title_back') > -1) {
            this.spotTitleBackList!.push(item)
          } else if (item.name.indexOf('_title') > -1) {
            this.spotTitleList!.push(item)
          } else if (item.name.indexOf('_line') > -1) {
            this.spotLineList!.push(item)
          }
        })
      }
      // 初始如果是normal模式显示标签，如果是全景模式则隐藏
      if (entryMode === 'normal') {
        this.showAllVisibleTags();
      } else {
        this.hideAllTags();
      }

      resourceLoadEnd();
    }

    app.lifeHooks.sceneLoadEnd = async () => {
      transitionAPI.event.on('toNormalView', () => {
        if(this.multiFloor){
          this._showTagsAfterFloor()
        }else{
          this.showAllVisibleTags();
        }
      })
      transitionAPI.event.on('toTopView', () => {
        this.hideAllTags();
      })
      transitionAPI.event.on('toSpot', () => {
        this.hideAllTags();
      })
      sceneLoadEnd();
    }

    app.lifeHooks.update = (deltaTime: number, inputContext: any) => {
      const dir: vec3 = this.tide.Camera.MainCamera!.entity.transform.forward();
      this.spotTitleBackList!.forEach((titleItem) => {
        titleItem.transform.setForward(dir, this.tide.Camera.MainCamera?.entity.transform.up());
      })
      // 多楼层时显示激活楼层的场景标签
      if(this.multiFloor){
        const transitionAPI = app.getInstance("transitionControlAPI") as any;
        if(this.crtFloorStatus === transitionAPI.activeFloor) return;
        this._time += deltaTime * 10;
        if (this._time >= 1) {
          this.crtFloorStatus = transitionAPI.activeFloor;
          this._time = 0;
          this._showTagsAfterFloor();
        }
      }
      update(deltaTime, inputContext);
    }
  }

  private _showTagsAfterFloor() {
    const configAPI = this.app.getInstance("configAPI") as any;
    const hotSpotAPI = this.app.getInstance("hotSpotAPI") as any;
    const modelConfig = configAPI.getConfig();
    const soptList = modelConfig.scenes;
    const currentSpotId = hotSpotAPI.getCurrentSpotId();
    if(this.crtFloorStatus === 'total' && currentSpotId === 'normal'){
      this.showAllVisibleTags();
      return;
    }else if(currentSpotId === 'top'){
      return;
    }
    this.hideAllTags();
    for(let sopt in soptList){
      const tagName = sopt + '_tag';
      if(soptList[sopt].floor === this.crtFloorStatus && this.spotTagShowMap[tagName]){
        this.showTagBySpotId(sopt);
      }
    }
  }

  private _initOptions() {
    const { lineLength, titleScale } = this.options;
    if (lineLength > MaxLineLength) {
      this.options.lineLength = MaxLineLength;
    }
    if (lineLength < MinLineLength) {
      this.options.lineLength = MinLineLength;
    }
    if (titleScale > MaxTitleScale) {
      this.options.titleScale = MaxTitleScale;
    }
    if (titleScale < MinTitleScale) {
      this.options.titleScale = MinTitleScale;
    }
  }

  private async creatTextureByImage(base64: string) {
    const titleBackImage = await getImage(base64);
    const texture = new this.tide.Texture2D(titleBackImage.width, titleBackImage.height);
    texture.setTextureImageSource(titleBackImage, 0, true);
    texture.generateMipmaps();
    return texture
  }

  /**
   * 创建spot标签
   * @param config
   * @returns
   */
  private async _createSpotTag(config: any) {
    const { spotId, visible } = config;
    const lineEntity = this._createSpotLine(config) as any;
    const titleBackEntity = await this._createSpotTitleBack(config) as any;
    const titleEntity = await this._createSpotTitle(config) as any;
    titleEntity.transform.localPosition[2] = 0.01; // z轴微调避免遮挡
    titleEntity.setParent(titleBackEntity!);
    const tagEntity = new this.tide.Entity(spotId + '_tag');
    this.spotTagShowMap[spotId + '_tag'] = visible;
    lineEntity.setParent(tagEntity);
    titleBackEntity.setParent(tagEntity);
    return tagEntity;
  }

  /**
   * 创建spot标签的线
   * @param config
   * @returns
   */
  private _createSpotLine(config: any) {
    const { spotId, startPosition, normal } = config;
    const startPos = vec3.create()
    // 起始点位向上延伸1m
    vec3.add(startPos, startPosition, vec3.scale(vec3.create(), normal, 1))
    const attrs: any = {
      verts: [],
      uvs: [0, 0, 0, 0],
      norms: [1, 0, 0, 0, 1, 0, 0, 0],
      indices: [0, 1],
      min: vec3.fromValues(-10, -10, -10),
      max: vec3.fromValues(10, 10, 10),
      isBufferGeometry: false
    };
    attrs.verts = [...[0, 0, 0], ...[0, 0, 1]];
    const lineEntity = new this.tide.Entity(spotId + '_line');
    const meshFilter = lineEntity.addComponent(this.tide.MeshFilter) as any;
    if (meshFilter) {
      meshFilter.mesh = new this.tide.Mesh(lineEntity.name + '_mesh', attrs, lineEntity.name + '_mesh');
      meshFilter.mesh.upLoad();
    }
    const render = lineEntity.addComponent(this.tide.Renderer) as any;
    render.isActive = false;
    render.material = new this.tide.PureColorMaterial();
    render.material!.drawType = this.tide.Constants.LINES;
    render!.material!.renderQueue = this.tide.RenderQueue.Transparent;
    (render.material as any).color = vec4.fromValues(1.0, 1.0, 1.0, 1);
    // render.material.depthTest = false;
    // render.material.depthWrite = false;
    lineEntity.transform.position = startPos;
    if (Math.abs(vec3.dot(normal, this.tide.Transform.Up)) > 0.999) {
      lineEntity.transform.setForward(normal, [1, 0, 0]);
    } else {
      lineEntity.transform.setForward(normal, [0, 1, 0]);
    }
    const lineScale = this.options.lineLength - (1.3 + this.scaleRatio); // 微调线段长度 不遮挡title框
    lineEntity.transform.localScale = [lineScale, lineScale, lineScale];
    return lineEntity;
  }

  /**
   * 创建spot标签的标题
   * @param config
   * @returns
   */
  private async _createSpotTitle(config: any) {
    const { spotId, name } = config;
    const titleEntity = this.tide.Entity.CreatePrimitive(this.tide.PrimitiveType.Plane)!;
    titleEntity.name = spotId + '_title';
    titleEntity.transform.localScale = [1, 1, 1];
    const renderer = titleEntity.getComponentByType(this.tide.Renderer) as any;
    if (!renderer) { return }
    renderer.isActive = false;
    const m = new this.tide.UnlitMaterial();
    renderer!.material = m;
    renderer!.material!.cullface = -1;
    renderer!.material!.renderQueue = this.tide.RenderQueue.Transparent;
    renderer!.material!.transparent = true;
    const image = await canvasToText({ text: name, fontWeight: 'bold' });
    const texture = new this.tide.Texture2D(image.width, image.height);
    texture.setTextureImageSource(image, 0, true);
    texture.generateMipmaps();
    if (!texture) { return }
    texture.magFilter = this.tide.Constants.LINEAR;
    texture.minFilter = this.tide.Constants.LINEAR_MIPMAP_LINEAR;
    texture.anisotropic = 16;
    (renderer!.material! as any).albedo = texture;
    renderer.material.depthTest = false;
    renderer.material.depthWrite = false;
    return titleEntity;
  }

  private _createSpotTitleBack(config: any) {
    const { spotId, endPosition } = config;
    const titleBackEntity = this.tide.Entity.CreatePrimitive(this.tide.PrimitiveType.Plane)!;
    titleBackEntity.name = spotId + '_title_back';
    titleBackEntity.transform.position = endPosition;
    const _localScale = new Float32Array([1.6, 0.7, 1]).map(item => item * this.options.titleScale);
    titleBackEntity.transform.localScale = _localScale;
    const render = titleBackEntity.getComponentByType(this.tide.Renderer) as any;
    if (!render) {
      return
    }
    render.isActive = false;
    const m = new this.tide.UnlitMaterial();
    render!.material = m;
    render!.material!.cullface = -1;
    render!.material!.renderQueue = this.tide.RenderQueue.Transparent;
    render!.material!.transparent = true;
    (render.material as any).albedo = this.titleBackTexture;
    render.material.depthTest = false;
    render.material.depthWrite = false;
    return titleBackEntity;
  }

  /**
   * 隐藏所有标签
   *
   */
  public hideAllTags() {
    this.spotTagList!.forEach((tagItem) => {
      tagItem.deactivate();
    })
  }

  /**
   * 显示所有标签
   *
   */
  public showAllTags() {
    this.spotTagList!.forEach((tagItem) => {
      tagItem.activate();
    })
  }

  /**
   * 根据配置的visible字段显示标签
   */
  public showAllVisibleTags() {
    this.spotTagList!.forEach((tagItem) => {
      if (this.spotTagShowMap[tagItem.name]) {
        tagItem.activate();
      } else {
        tagItem.deactivate();
      }
    })
  }

  /**
   * 根据spot点id隐藏标签
   * @param id
   * @param updateConfig
   */
  public hideTagBySpotId(id: string, updateConfig?: boolean) {
    const filterTag = this.getTagBySpotId(id);
    filterTag.deactivate();
    if (updateConfig) {
      this.spotTagShowMap[filterTag.name] = false;
    }
  }

  /**
   * 根据spot点id显示标签
   * @param id
   * @param updateConfig
   */
  public showTagBySpotId(id: string, updateConfig?: boolean) {
    const filterTag = this.getTagBySpotId(id);
    filterTag.activate();
    if (updateConfig) {
      this.spotTagShowMap[filterTag.name] = true;
    }
  }

  /**
   * 根据spot点id查找标签
   * @param id
   * @returns
   */
  public getTagBySpotId(id: string) {
    const tagName = id + '_tag';
    const filterTag = this.spotTagList!.filter((item) => {
      return item.name === tagName;
    })[0];
    if (!filterTag) {
      throw new Error(`the ${id} spot tag is not find`)
    }
    return filterTag;
  }

  /**
   * 根据spot点id查找标签标题
   * @param id
   * @returns
   */
  public getTagTitleBySpotId(id: string) {
    const tagTitleName = id + '_title';
    const filterTagTitle = this.spotTitleList!.filter((item) => {
      return item.name === tagTitleName;
    })[0];
    if (!filterTagTitle) {
      throw new Error(`the ${id} spot tag title is not find`)
    }
    return filterTagTitle;
  }

  /**
   * 根据spot点id查找标签标题
   * @param id
   * @returns
   */
  public getTagTitleBackBySpotId(id: string) {
    const tagTitleBackName = id + '_title_back';
    const filterTagTitleBack = this.spotTitleBackList!.filter((item) => {
      return item.name === tagTitleBackName;
    })[0];
    if (!filterTagTitleBack) {
      throw new Error(`the ${id} spot tag title background is not find`)
    }
    return filterTagTitleBack;
  }

  /**
   * 根据spot点id查找标签线
   * @param id
   * @returns
   */
  public getTagLineBySpotId(id: string) {
    const tagLineName = id + '_line';
    const filterTagLine = this.spotLineList!.filter((item) => {
      return item.name === tagLineName;
    })[0];
    if (!filterTagLine) {
      throw new Error(`the ${id} spot tag line is not find`)
    }
    return filterTagLine;
  }



  /**
   * 设置标签的标题
   * @param id
   * @param url
   */
  public async setTagTitle(id: string, title: string) {
    const currentTitle = this.getTagTitleBySpotId(id);
    const renderer = currentTitle.getComponentByType(this.tide.Renderer) as any;
    if (!renderer) { return }
    const textString = title.length > 4 ? `${title.substring(0, 4)}...` : title;
    const image = await canvasToText({ text: textString, fontWeight: 'bold' });
    const texture = new this.tide.Texture2D(image.width, image.height);
    texture.setTextureImageSource(image, 0, true);
    texture.generateMipmaps();
    if (!texture) { return }
    texture.magFilter = this.tide.Constants.LINEAR;
    texture.minFilter = this.tide.Constants.LINEAR_MIPMAP_LINEAR;
    texture.anisotropic = 16;
    (renderer!.material! as any).albedo = texture;
  }

  /**
   * 高亮标签
   * @param id
   * @param color
   * @returns
   */
  public highLight(id: string) {
    if (this.isHighLight) return
    const currentLine = this.getTagLineBySpotId(id);
    const currentTitleBack = this.getTagTitleBackBySpotId(id);
    const currentEndPosition = vec3.clone(currentTitleBack.transform.position);
    currentEndPosition[1] = currentEndPosition[1] + scaleLength + 1.35 + this.scaleRatio;
    // 伸长1m
    const lineScale = this.options.lineLength + scaleLength;
    currentLine.transform.localScale = [lineScale, lineScale, lineScale]
    currentTitleBack.transform.position = currentEndPosition
    const renderer = currentLine.getComponentByType(this.tide.Renderer);
    const _renderer = currentTitleBack.getComponentByType(this.tide.Renderer);
    if (!renderer && !_renderer) { return }
    this.isHighLight = true;
    (renderer!.material as any).color = highLightLineColor as vec4;
    (_renderer!.material as any).albedo = this.highLightBackTexture
  }

  /**
   * 取消高亮标签
   * @param id
   * @returns
   */
  public unhighLight(id: string) {
    if (!this.isHighLight) return
    const currentLine = this.getTagLineBySpotId(id);
    const currentTitleBack = this.getTagTitleBackBySpotId(id);
    const unhighLightColor = vec4.fromValues(1.0, 1.0, 1.0, 1);
    const currentEndPosition = vec3.clone(currentTitleBack.transform.position);
    const lineScale = this.options.lineLength - (1.3 + this.scaleRatio)
    currentEndPosition[1] = currentEndPosition[1] - scaleLength - 1.35 - this.scaleRatio;
    currentLine.transform.localScale = [lineScale, lineScale, lineScale]
    currentTitleBack.transform.position = currentEndPosition
    const renderer = currentLine.getComponentByType(this.tide.Renderer);
    const _renderer = currentTitleBack.getComponentByType(this.tide.Renderer);
    if (!renderer && !_renderer) { return }
    this.isHighLight = false;
    (renderer!.material as any).color = unhighLightColor;
    (_renderer!.material as any).albedo = this.titleBackTexture
  }


}
