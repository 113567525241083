import * as TideWanhuatong from "@sdkCore/runtime";
import { spotArrow, doubleSpotArrow } from "./icon";
import { vec3 } from "gl-matrix";
import EventEmitter from "eventemitter3";

const { Tide, Application, getImage, WanHuaTongUtility, StandarMaterial } = TideWanhuatong
const { Constants, Entity, PrimitiveType, Renderer, SceneManager, Texture2D } = Tide
interface SmartRouteOptions {
  auto?: boolean
}
enum Event {
  autoEnd = 'autoEnd'
}
export default class SmartRoutePlugin {
  guideConfig: any
  app: any | null = null
  options: SmartRouteOptions = {}
  arrowTexture: any;
  doubleArrowTexture: any;
  arrowMap: Map<string, any> = new Map()
  event = new EventEmitter<Event.autoEnd>();

  private autoI: number = 0
  private autoJ: number = 0
  private autoStatus = true
  private openDraw = false;
  private currentScene: any;

  constructor(options: SmartRouteOptions) {
    this.options = {
      // 默认会自动行走智能导览，如果ICBU需要商家自己点击行走，那么可以传入auto: false
      auto: true,
      ...options
    }
  }

  // 入口方法
  start() {
    this.autoI = 0
    this.autoJ = 0
    this.autoStatus = true
    if (JSON.stringify(this.guideConfig) === '{}') {
      return
    }
    this.openDraw = true;
    this.drawArrowPath();
    if(this.options.auto) {
      this.autoRun()
    }
  }

  async autoRun() {
    if (JSON.stringify(this.guideConfig) === '{}') {
      return
    }
    this.autoStatus = true
    const paths = this.guideConfig.paths
    const transitionAPI = this.app!.getInstance("transitionControlAPI") as any;
    // 此处必须用for，而不能是await Promise.all,因为要一个一个执行，而不是同时进行
    for (let i = this.autoI; i < paths.length; i++) {
      if (!this.autoStatus) {
        return
      }
      const spots = paths[i].spots
      this.autoI = i
      for (let j = this.autoJ; j < spots.length; j++) {
        if (!this.autoStatus) {
          break
        }
        this.autoJ = j
        try {
          if (spots[j + 1]) {
            await transitionAPI.transitionAndFaceToSpot(spots[j], spots[j + 1], {
              duration: 2
            })
          } else {
            await transitionAPI.transitionTo(spots[j], {
              duration: 2
            })
          }
        } catch (err) {
          // 注意：如果点位隐藏的话，就会报错，所以算法的路径图需要忽略的商家隐藏的点位
        }
        // 跳转后，在当前点停留1s
        await new Promise((re) => {
          setTimeout(() => {
            re(true)
          }, 1000)
        })
      }
      if (!this.autoStatus) {
        return
      }
      if (this.autoI === paths.length - 1 && this.autoJ === paths[this.autoI].spots.length - 1) {
        this.event.emit(Event.autoEnd)
      }
      this.autoJ = 0
    }
    this.autoI = 0
  }

  async terminateRun() {
    this.autoStatus = false
  }

  // 关闭方法
  stop() {
    this.openDraw = false;
    this.disposeArrow();
    this.autoStatus = true
  }


  // 箭头路线绘制
  async drawArrowPath() {
    const paths = this.guideConfig?.paths
    for(let j = 0; j < paths.length; j++) {
      const drawSpotPath = paths[j].spots;
      for (let i = 0; i < drawSpotPath.length - 1; i++) {
        const startSpotId = drawSpotPath[i]
        const endSpotId = drawSpotPath[i + 1]
        const arrowEntity = await this.drawArrow(startSpotId, endSpotId);
        if (!this.arrowMap.has(`${startSpotId}-${endSpotId}`) && !this.arrowMap.has(`${endSpotId}-${startSpotId}`)) {
          this.arrowMap.set(`${startSpotId}-${endSpotId}`, arrowEntity)
        }
      }
    }
  }

  // 绘制箭头
  async drawArrow(startSpotId: any, endSpotId: any) {
    const configAPI = this.app!.getInstance("configAPI") as any;
    const data = configAPI.getConfig();
    const scenes = data.scenes;
    const start = scenes[startSpotId];
    const end = scenes[endSpotId];
    if (this.arrowMap.has(`${startSpotId}-${endSpotId}`) || this.arrowMap.has(`${endSpotId}-${startSpotId}`)) {
      const entity = this.arrowMap.get(`${startSpotId}-${endSpotId}`) || this.arrowMap.get(`${endSpotId}-${startSpotId}`)
      const renderer = entity.getComponentByType(Renderer);
      if (renderer) {
        renderer.material.albedo = this.doubleArrowTexture;
      }
      return entity
    }
    const startPos = WanHuaTongUtility.matchCoordSys(start.spotPose.groundCoord)
    const endPos = WanHuaTongUtility.matchCoordSys(end.spotPose.groundCoord)
    const dir: vec3 = vec3.sub(vec3.create(), endPos, startPos)// 相减得到两个点位之间位置得到方向向量
    vec3.normalize(dir, dir)// 向量归一化
    const distance = vec3.distance(endPos, startPos)// 两点之间的距离
    const middlePos: vec3 = vec3.add(vec3.create(), startPos, vec3.scale(vec3.create(), dir, distance / 2))// 两个点的中间位置
    middlePos[1] += 0.1
    // 创建实体类
    const entity = Entity.CreatePrimitive(PrimitiveType.Plane) as any;
    entity.transform.setForward(dir)// 朝向对齐
    entity.transform.rotate(vec3.fromValues(-90, 0, 0))//旋转图标
    entity.transform.position = middlePos
    entity.transform.localScale = vec3.fromValues(0.2, 0.5, 0.5)// 缩放图标
    const renderer = entity.getComponentByType(Renderer);
    if (renderer) {
      const m = new StandarMaterial()
      m.albedo = this.arrowTexture;
      renderer.material = m
    }
    this.currentScene.addEntity(entity)
    return entity;
  }

  // 生成箭头纹理
  async initTexture(arrow: any) {
    let texture = undefined
    const image = await getImage(arrow)
    texture = new Texture2D(image.width, image.height);
    texture.setTextureImageSource(image);
    texture.magFilter = Constants.LINEAR;
    texture.minFilter = Constants.LINEAR_MIPMAP_LINEAR;
    texture.anisotropic = 16;
    texture.generateMipmaps();
    return texture;
  }

  // 摧毁箭头
  disposeArrow() {
    if (this.arrowMap.size) {
      this.arrowMap.forEach((item) => {
        item.deactivate();
        this.currentScene.destroyEntity(item);
      })
      this.arrowMap = new Map()
    }
  }



  install(app: any, options: any) {
    this.app = app;
    const sceneLoadEnd = app.lifeHooks.sceneLoadEnd;
    const update = app.lifeHooks.update
    const transitionAPI = app.getInstance("transitionControlAPI") as any;

    app.lifeHooks.sceneLoadEnd = async () => {
      this.currentScene = SceneManager.GetInstance().currentScene //获取当前场景
      this.guideConfig = await Application.Config.configResolver.resolve('route/guide_config.json') || {}
      this.arrowTexture = await this.initTexture(spotArrow);
      this.doubleArrowTexture = await this.initTexture(doubleSpotArrow);
      transitionAPI.event.on('toNormalView', () => {
        if (this.openDraw) {
          this.disposeArrow() // 销毁箭头
        }
      })
      transitionAPI.event.on('toTopView', () => {
        if (this.openDraw) {
          this.disposeArrow() // 销毁箭头
        }
      })
      sceneLoadEnd(); // 必须调用
    }
    app.lifeHooks.update = (deltaTime: any, inputContext: any) => {
      update(deltaTime, inputContext); // 必须调用
    }
  }

}
