export interface Iposition {
  x: number,
  y: number
}

export enum stageState {
  view = 'view',
  edit = 'edit',
}

export enum displayIndex {
  uselessSpotIndex = 0,
  pathIndex = 1,
  spotIndex = 2,
  spotLableIndex = 3,
  locationSignIndex = 4
}

