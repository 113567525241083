import Palette from '@ali/magic-brush'
import { vec3ToVec2 } from './util'
import { Subscription } from 'rxjs'

interface IMagicBrushOptions {
  configResolver: {
    resolve: Function
  },
  container: string
}

export default class MagicBrush {
  app: any | null = null
  options: IMagicBrushOptions
  instance: typeof Palette
  orthomapConfig: any | null = null
  transitionSubscription: Subscription | null = null
  frameId: number | null = null

  constructor(options: IMagicBrushOptions) {
    this.options = options
  }

  async _init() {
    console.log(this.app)
    const { configResolver, container } = this.options
    const containerElement = document.querySelector(container)
    if(!containerElement) {
      console.error('未能文档流中找到此元素')
      return
    }
    const orthomapConfig = await configResolver.resolve('orthomap/orthomap.json')
    this.orthomapConfig = orthomapConfig
    const floorData = orthomapConfig.images[0]
    const vectorMap = floorData.vectorMap
      ? JSON.parse(JSON.stringify(floorData.vectorMap))
      : null
    let plottingScale: number = 0
    if (floorData) {
      plottingScale = 1000 / floorData.Ts[0]
    }
    const instance = new Palette({
      containerId: 'VECTOR_MINI_MAP',
      mode: 'view', // 必须
      width: containerElement.clientWidth,
      height: containerElement.clientHeight,
      data: vectorMap,
      plottingScale,
      rotation: (orthomapConfig.rotationAngle * Math.PI) / 180,
      ratio: orthomapConfig.scale,
      styleOptions: {
        wallColor: 0xffffff,
      },
      autoPreventDefault: false,
      autoResize: false,
    })
    instance.scale((containerElement.clientWidth / 550) * orthomapConfig.scale)
    this.instance = instance
  }

  async addPointer() {
    const { container } = this.options
    const configApi = this.app.getInstance('configAPI')
    const hotSpotApi = this.app.getInstance('hotSpotAPI')
    const cameraApi = this.app.getInstance('cameraAPI')
    const transitionApi = this.app.getInstance('transitionControlAPI')
    const config = configApi.getConfig()
    const hotSpots = new Map()
    Object.keys(config.scenes).forEach((key: string) => {
      const value = config.scenes[key]
      hotSpots.set(key, {
        key,
        spotPose: value.spotPose,
        position2d: vec3ToVec2(
          value.spotPose.opticalCenter,
          this.orthomapConfig.images[0].transform,
          1
        ),
      })
    })
    const currentSpotId = hotSpotApi.getCurrentSpotId() as string
    const containerElement = document.querySelector(container)!
    const minimapContainer = document.createElement('div') as HTMLElement
    minimapContainer.id = 'minimap-position-container'
    minimapContainer.className = 'minimap-position-container'
    minimapContainer.style.width = '20px'
    minimapContainer.style.height = '22px'
    minimapContainer.style.position = 'absolute'
    minimapContainer.style.top = '0px'
    minimapContainer.style.left = '0px'
    minimapContainer.style.transition='transform 1.5s ease'
    minimapContainer.innerHTML =
      `<div
          id='minimap-position-content'
          class="minimap-position-content"
          style='width: 100%; height: 100%; display: flex;'
        >
          <img src="https://img.alicdn.com/imgextra/i3/O1CN01HJbDFY1Ccl3zNcfxP_!!6000000000102-2-tps-40-44.png" class="minimap-position-img" />
        </div>`
    containerElement.appendChild(minimapContainer)
    const transitionTo = (spotId: string) => {
      const toSpot = hotSpots.get(spotId)
      const mapImgScale = containerElement.clientWidth / 550
      const translateX = toSpot.position2d.x * mapImgScale - 10
      const translateY = toSpot.position2d.y * mapImgScale - 11
      minimapContainer.style.transform = `translate(${translateX}px, ${translateY}px)`
    }
    const loop = () => {
      const spotId = hotSpotApi.getCurrentSpotId()
      if (spotId !== 'normal' && spotId !== 'top') {
        const [theta] = cameraApi.getCameraParams().splice(0, 2)
        if (!isNaN(theta)) {
          const angle =
            180 - (theta * 180) / Math.PI + this.orthomapConfig.rotationAngle
          const content = document.querySelector('#minimap-position-content') as HTMLElement
          content.style.transform = `rotate(${angle}deg)`
          content.style.transformOrigin = 'center'
        }
      }
      this.frameId = requestAnimationFrame(loop)
    }
    transitionTo(currentSpotId)

    if (!this.transitionSubscription) {
      this.transitionSubscription = transitionApi.transitionProcessObserve.subscribe((params: any) => {
        if (params.state === 'BeforeTransition') {
          const toSpotId = params.to.spotId
          if (['top', 'normal'].indexOf(toSpotId) === -1) {
            transitionTo(toSpotId)
          }
        }
      })
    }
    loop()
  }
  async removePointer() {
    const { container } = this.options
    const containerElement = document.querySelector(container)!
    const pointer = containerElement.querySelector('.minimap-position-container')
    if (pointer) {
      containerElement.removeChild(pointer)
    }
    if(this.transitionSubscription) {
      this.transitionSubscription.unsubscribe()
    }
    if(this.frameId) {
      cancelAnimationFrame(this.frameId)
    }
  }

  destroy() {
    this.instance.destroy()
  }

  clear() {
    this.instance.clear()
  }

  install(app: any, optionss: any) {
    this.app = app;
    const sceneLoadEnd = app.lifeHooks.sceneLoadEnd;
    const update = app.lifeHooks.update

    app.lifeHooks.sceneLoadEnd = async () => {
      sceneLoadEnd(); // 必须调用
    }
    app.lifeHooks.update = (deltaTime: any, inputContext: any) => {
      update(deltaTime, inputContext); // 必须调用
    }
    this._init()
  }
}


