import { getImage } from "../../GameLogic/WanHuaTongUtility";

export const canvasToText = async function(params: any) {
  const canvas = document.createElement('canvas', { is: 'miniprogram-use-offscreen' });
  const ctx = canvas.getContext('2d');
  canvas.width = params.width || 256;
  canvas.height = params.height || 128;
  const fontWeight = params.fontWeight || 'normal';
  const fontStyle = params.fontStyle || '微软雅黑';
  const fontSize = params.fontSize || '56px';
  const fontColor = params.fontColor || '#fff';
  const textAlign = params.textAlign || 'center';
  if (ctx) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = `${fontWeight} ${fontSize} ${fontStyle}`;
    ctx.fillStyle = fontColor;
    ctx.textAlign = textAlign as CanvasTextAlign;
    ctx.textBaseline = 'middle';
    if (textAlign === 'left') {
      ctx.fillText(params.text, 0, canvas.height / 2);
    } else {
      ctx.fillText(params.text, canvas.width / 2, canvas.height / 2);
    }
  }
  const image = await canvasToImage(canvas);
  return image;
}

export const canvasToImage = async (canvas: HTMLCanvasElement): Promise<any> => {
  const url = canvas.toDataURL('image/png');
  const image = await getImage(url);
  return image;
};