export enum spotType {
  default = 'default',
  inPath = 'inPath',
  inVoice = 'inVoice',
}

export interface IspotStyleConfig {
  fillColor: any
  outlineWidth: number
  outlineColor: any
  circleRadius: number
  selectedOutlineColor: any
}