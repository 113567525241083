import { pathType } from '../interface/path';
import { Iposition } from '../interface/base';
import { pathStyleConfig } from '../config/path';
import { Spot } from './spot'
import * as PIXI from 'pixi.js';

export class Path {
  name: string;
  type: pathType;
  startPosition: Iposition;
  endPosition: Iposition;
  startSpot: Spot;
  endSpot: Spot;
  line: any;

  constructor (config: any) {
    const { name, type, startPosition, endPosition, startSpot, endSpot} = config;
    this.startSpot = startSpot
    this.endSpot = endSpot
    this.name = name;
    this.type = type;
    this.startPosition = startPosition;
    this.endPosition = endPosition;
    this._initLine();
    this.drawLine();
  }

  /**
   * 初始化line
   */
  private _initLine() {
    this.line = new PIXI.Graphics();
    const pathStyle = (pathStyleConfig as any)[this.type];
    this.line.lineStyle(pathStyle.lineWidth, pathStyle.lineColor, 1);
  }

  /**
   * 画线
   * @param start
   * @param end
   */
  public drawLine() {
    this.line.moveTo(this.startPosition.x, this.startPosition.y);
    this.line.lineTo(this.endPosition.x, this.endPosition.y);
  }

  /**
   * 改变path类型
   * @param type
   */
  public changePathType(type: pathType) {
    this._setProperty('type', type);
    this.line.clear();
    this.line.lineStyle((pathStyleConfig as any)[type].lineWidth, (pathStyleConfig as any)[type].lineColor, 1);
    this.drawLine();
  }

  /**
   * 设置属性和对应值
   * @param property
   * @param value
   */
  private _setProperty(property: string, value: any) {
    Object.assign(this, {
      [property]: value
    })
  }
}

